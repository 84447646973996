import {
  useLazyCheckDuplicateLoginIdQuery,
  useLazyCheckDuplicateTenantIdQuery,
  useSignupMutation,
} from '@crew/apis/dist/app/appApis'
import { SignupRequest } from '@crew/apis/dist/app/models/signup/request'
import {
  DEFAULT_PASSWORD_COMPLEX_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_SYMBOLS,
  SEARCH_TIMEOUT_MSEC,
} from '@crew/configs/constants'
import { BillingCycle, ContractPlan } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { ValidationStatus } from 'devextreme/common'

import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { validateContractPlan } from 'utils'

export type FormValues = {
  companyName: string
  tenantAlias: string
  organizationName: string
  loginId: string
  displayName: string
  password: string
  confirmPassword: string
  contactName: string
  departmentName: string
  officialPosition: string
  tel: string
}
const formInitialValues: FormValues = {
  companyName: '',
  tenantAlias: '',
  organizationName: '',
  loginId: '',
  displayName: '',
  password: '',
  confirmPassword: '',
  contactName: '',
  departmentName: '',
  officialPosition: '',
  tel: '',
}
export const useAppSignup = (paramPlan: string | null) => {
  const { t } = useTranslation()

  const [tenantAliasValidationStatus, setTenantAliasValidationStatus] =
    useState<ValidationStatus>('valid')

  const [loginIdValidationStatus, setLoginIdValidationStatus] =
    useState<ValidationStatus>('valid')

  // Get signup function
  const [signupMutation] = useSignupMutation()

  // Signup process
  const signup = useCallback(
    async (
      data: FormValues,
      token: string,
      plan: ContractPlan,
      billingCycle: BillingCycle
    ) => {
      const params: SignupRequest = {
        companyName: data.companyName,
        tenantAlias: data.tenantAlias,
        organizationName: data.organizationName,
        loginId: data.loginId,
        displayName: data.displayName,
        password: data.password,
        signupToken: token,
        plan,
        billingCycle,
        contactName: undefined,
        departmentName: undefined,
        officialPosition: undefined,
        tel: undefined,
      }

      if (plan === ContractPlan.Free) {
        // フリープランの時は連絡先情報をセットする
        params.contactName = data.contactName
        params.departmentName =
          data.departmentName === '' ? undefined : data.departmentName
        params.officialPosition =
          data.officialPosition === '' ? undefined : data.officialPosition
        params.tel = data.tel
      }

      // サインアップAPIを呼び出す
      await signupMutation(params).unwrap()
    },
    [signupMutation]
  )

  // react-hook-formの各種データを取得
  const {
    control,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm<FormValues>({
    defaultValues: formInitialValues,
    criteriaMode: 'all',
  })

  // Verify password
  const checkPasswordConfirm = useCallback(() => {
    return getValues('password') === getValues('confirmPassword')
  }, [getValues])

  // Check require tenant ID and update validation state
  const checkTenantIdEmpty = useCallback(async () => {
    const tenantId = getValues('tenantAlias')
    if (tenantId.trim().length === 0) {
      setTenantAliasValidationStatus('invalid')
      return false
    }
    return true
  }, [getValues])

  // Check the required for login id
  const checkRequiredForLoginId = useCallback(() => {
    const loginId = getValues('loginId')

    if (loginId.trim().length === 0) {
      setLoginIdValidationStatus('invalid')
      return false
    }

    return true
  }, [getValues])

  // Check the policy for login id
  const checkPolicyForLoginId = useCallback(() => {
    const loginId = getValues('loginId')

    // 文字数が4文字未満の場合、エラー
    if (loginId.trim().length < 4) {
      setLoginIdValidationStatus('invalid')
      return false
    }

    // 使用できない文字が含まれている場合、エラー
    // (使用可能な文字：英字大文字・英字小文字・数字・ハイフン）
    const regex = /^[A-Za-z0-9-]*$/
    if (!regex.test(loginId)) {
      setLoginIdValidationStatus('invalid')
      return false
    }

    return true
  }, [getValues])

  // フリープランの場合のみ契約者氏名の有無をチェック
  const checkRequiredContactNameForFreePlan = useCallback(() => {
    if (!validateContractPlan(paramPlan)) {
      // 定義されているプランと合致しない場合はエラー
      return false
    }

    if (paramPlan === ContractPlan.Free) {
      // プランがフリープランの場合、対象の値が空でないことをチェック
      if (getValues('contactName').trim().length === 0) {
        return false
      }
    }

    // フリープラン以外の場合はチェックしないのでtrue
    return true
  }, [getValues, paramPlan])

  // フリープランの場合のみ電話番号の有無をチェック
  const checkRequiredTelForFreePlan = useCallback(() => {
    if (!validateContractPlan(paramPlan)) {
      // 定義されているプランと合致しない場合はエラー
      return false
    }

    if (paramPlan === ContractPlan.Free) {
      const tel = getValues('tel')
      // プランがフリープランの場合、対象の値が空でないことをチェック
      if (tel.trim().length === 0) {
        return t('message.general.isRequired', {
          name: t('label.tel'),
        })
      }

      // 空でなかった場合、[数字]-[数字]-[数字]の形式であることをチェック
      const regex = /^\d{1,4}-\d{1,4}-\d{1,4}$/
      if (!regex.test(tel)) {
        // setLoginIdValidationStatus('invalid')
        return t('message.general.invalidTel')
      }
    }
    // フリープラン以外の場合はチェックしないのでtrue
    return true
  }, [getValues, paramPlan, t])

  // 以下、入力コンポーネントのルールとレンダリングを定義
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      companyName: {
        required: t('message.general.isRequired', {
          name: t('label.companyName'),
        }),
      },
      tenantAlias: {
        validate: {
          checkTenantIdEmpty: () =>
            checkTenantIdEmpty() ||
            t('message.general.isRequired', {
              name: t('label.tenantId'),
            }),
        },
      },
      organizationName: {
        required: t('message.general.isRequired', {
          name: t('label.organizationName'),
        }),
      },
      loginId: {
        validate: {
          always: () =>
            checkRequiredForLoginId() ||
            t('message.general.isRequired', {
              name: t('label.loginId'),
            }),
          loginIdPolicy: () =>
            checkPolicyForLoginId() ||
            t('message.signup.register.invalidLoginId'),
        },
      },
      displayName: {
        required: t('message.general.isRequired', {
          name: t('label.displayName'),
        }),
      },
      password: {
        required: t('message.general.isRequired', {
          name: t('label.password'),
        }),
        // サインアップ時のパスワードのポリシーは「複雑なパスワードを強制する」と同じポリシーとする
        // 「複雑なパスワード」かつ「8文字以上」かつ「32文字以下」
        pattern: {
          value: new RegExp(
            `^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[${PASSWORD_SYMBOLS}]).{${DEFAULT_PASSWORD_COMPLEX_MIN_LENGTH},${PASSWORD_MAX_LENGTH}}$`
          ),
          message: t('message.signup.register.invalidFormatPassword'),
        },
      },
      confirmPassword: {
        required: t('message.general.isRequired', {
          name: t('label.confirmPassword'),
        }),
        validate: {
          checkPasswordConfirm: () =>
            checkPasswordConfirm() ||
            t('message.signup.register.invalidFormatConfirmPassword'),
        },
      },
      contactName: {
        validate: {
          checkRequiredContactNameForFreePlan: () =>
            checkRequiredContactNameForFreePlan() ||
            t('message.general.isRequired', {
              name: t('label.contactName'),
            }),
        },
      },
      departmentName: {},
      officialPosition: {},
      tel: {
        validate: {
          checkRequiredTelForFreePlan: () => checkRequiredTelForFreePlan(),
        },
      },
    }),
    [
      checkPasswordConfirm,
      checkPolicyForLoginId,
      checkRequiredContactNameForFreePlan,
      checkRequiredForLoginId,
      checkRequiredTelForFreePlan,
      checkTenantIdEmpty,
      t,
    ]
  )

  // Check valid subdomain
  // used async validation
  // https://js.devexpress.com/Demos/WidgetsGallery/Demo/Validation/Overview/React/Light/
  const checkValidSubdomain = useCallback((value: string) => {
    // Regex check valid subdomain
    // Allow only lowercase letters, numbers and hyphens
    // Must start with a letter
    // Must end with a letter or number
    // Must be between 3 and 20 characters long
    const regexPattern = /^[a-z][a-z0-9-]{1,18}[a-z0-9]$/
    return new Promise((resolve) => {
      window.setTimeout(() => {
        resolve(regexPattern.test(value))
      }, SEARCH_TIMEOUT_MSEC)
    })
  }, [])

  const [lazyCheckDuplicateTenantIdQuery] = useLazyCheckDuplicateTenantIdQuery()
  const [lazyCheckDuplicateLoginIdQuery] = useLazyCheckDuplicateLoginIdQuery()

  // Check the existence of tenantId
  const verifyTenantId = useCallback(
    async (tenantId: string) => {
      // Check duplicate tenant ID
      // Call API check cuplicate tenant ID
      const response = await lazyCheckDuplicateTenantIdQuery({
        tenantId,
      }).unwrap()
      if (response.duplicated) {
        // Duplicated
        setTenantAliasValidationStatus('invalid')
        setError('tenantAlias', {
          type: 'manual',
          message: t('message.signup.register.tenantIdAlreadyExists'),
        })
      } else {
        // Not duplicated
        setTenantAliasValidationStatus('valid')
        clearErrors('tenantAlias')
      }
    },
    [clearErrors, lazyCheckDuplicateTenantIdQuery, setError, t]
  )

  // Check the existence of loginId
  const verifyLoginId = useCallback(
    async (loginId: string) => {
      // Call API check duplicate login ID
      const response = await lazyCheckDuplicateLoginIdQuery({
        loginId,
      }).unwrap()
      if (response.duplicated) {
        // Duplicated
        setLoginIdValidationStatus('invalid')
        setError('loginId', {
          type: 'manual',
          message: t('message.signup.register.loginIdAlreadyExists'),
        })
      } else {
        // Not duplicated
        setLoginIdValidationStatus('valid')
        clearErrors('loginId')
      }
    },
    [clearErrors, lazyCheckDuplicateLoginIdQuery, setError, t]
  )

  return {
    control,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    trigger,
    validateRules,

    tenantAliasValidationStatus,
    setTenantAliasValidationStatus,

    signup,
    checkValidSubdomain,
    verifyTenantId,
    verifyLoginId,

    loginIdValidationStatus,
    setLoginIdValidationStatus,
  }
}
