import { memo, FC } from 'react'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'

type EventDetailWebMeetingWaitingAttendeesProps = {
  chimeAttendeeId: string
  displayName: string
  attendeeUserId: string
}

export const EventDetailWebMeetingWaitingAttendeeItem: FC<EventDetailWebMeetingWaitingAttendeesProps> =
  memo((props) => {
    const { t } = useTranslation()

    return (
      <>
        <div className="flex justify-between items-center gap-2.5">
          <div className="min-w-0 flex flex-row items-center gap-0.5">
            <div className="min-w-0 grow flex flex-row items-center gap-2.5">
              <CrewUserAvatar
                userId={props.attendeeUserId}
                displayName={props.displayName}
                size={CrewAvatarSize.xs}
                showLabel={false}
                cacheValue={props.attendeeUserId}
                disableClick
              />
              <div className="flex flex-col flex-1">
                <p className="line-clamp-1">{props.displayName}</p>
                <p className="crew-text-gray-4 text-xs line-clamp-1">
                  {t('label.askToJoinMeeting')}
                </p>
              </div>
            </div>
          </div>

          <div className="flex gap-x-1 items-center">
            <CrewButton
              type="normal"
              text={t('label.permit')}
              stylingMode="outlined"
              size="sm"
            />
            <CrewButton
              type="normal"
              text={t('label.reject')}
              stylingMode="outlined"
              size="sm"
            />
          </div>
        </div>
      </>
    )
  })
