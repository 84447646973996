import { RefObject, memo } from 'react'
import { useCrewAttendees } from 'components/chime/crewAttendees/useCrewAttendees'
import { EventDetailWebMeetingLocalAttendee } from './components/eventDetailWebMeetingLocalAttendee/eventDetailWebMeetingLocalAttendee'
import { EventDetailWebMeetingRemoteAttendee } from './components/eventDetailWebMeetingRemoteAttendee/eventDetailWebMeetingRemoteAttendee'
import { MeetingAttendeeJoinState } from '@crew/enums/dist/domain'
import { EventDetailWebMeetingWaitingAttendeeItem } from './components/eventDetailWebMeetingWaitingAttendeeItem/eventDetailWebMeetingWaitingAttendeeItem'

type EventDetailWebMeetingAttendeeListProps = {
  isFullScreen: boolean
  fullScreenTargetRef: RefObject<HTMLDivElement>
}

export const EventDetailWebMeetingAttendeeList = memo(
  (props: EventDetailWebMeetingAttendeeListProps) => {
    const { attendeeUsers, localAttendeeUser } = useCrewAttendees()

    return (
      <div className="flex flex-col w-72 h-full p-3 gap-y-1 overflow-y-auto">
        {/* show attendee local */}
        {localAttendeeUser && (
          <EventDetailWebMeetingLocalAttendee
            attendee={{
              ...localAttendeeUser,
              attendeeUserId: localAttendeeUser.id,
            }}
            isFullScreen={props.isFullScreen}
            fullScreenTargetRef={props.fullScreenTargetRef}
            // また、ログインユーザーが主催者であっても、参加者＝ログインユーザーのメニューボタンについては非表示とすること
            showAttendeeActionMenu={false}
          />
        )}
        {/* show list attendee invite meeting */}
        {attendeeUsers?.map((user) => {
          if (
            user.joinState === MeetingAttendeeJoinState.Waiting &&
            !localAttendeeUser?.isHost
          ) {
            // if the logged in user is not the Host, do not show waiting attendees
            return <></>
          }

          return user.joinState === MeetingAttendeeJoinState.Waiting ? (
            // 待機中のユーザー
            <EventDetailWebMeetingWaitingAttendeeItem
              key={user.id}
              chimeAttendeeId={user.chimeAttendeeId}
              attendeeUserId={user.id}
              displayName={user.displayName}
            />
          ) : (
            // 参加済みのユーザー
            <EventDetailWebMeetingRemoteAttendee
              key={user.id}
              attendee={{
                ...user,
                attendeeUserId: user.id,
              }}
              isFullScreen={props.isFullScreen}
              fullScreenTargetRef={props.fullScreenTargetRef}
              // ログインユーザーが主催者ではない場合は、メニューボタン（…）は非表示とする
              showAttendeeActionMenu={localAttendeeUser?.isHost ?? false}
            />
          )
        })}
      </div>
    )
  }
)
