import { useUpdateFileMutation } from '@crew/apis/file/fileApis'
import { File as FileModel } from '@crew/models/domain'
import { UploadFile } from 'models/domain/uploadFile'
import { NotifyEventType } from 'enums/app'
import {
  notifyFileEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'
import { File } from '@crew/apis/dist/file/models/getFile/response'

export const useFileDetailPage = () => {
  const dispatch = useAppDispatch()

  // Get function for update file
  const [updateFileMutation] = useUpdateFileMutation()

  // Update file process
  const updateFile = useCallback(
    async (
      fileUpdate: File,
      newDescription: string,
      needNotification: boolean,
      uploadFile: UploadFile
    ) => {
      const payload = {
        id: fileUpdate.id,
        entityType: fileUpdate.entityType,
        entityRecordId: fileUpdate.entityRecordId,
        name: fileUpdate.name,
        fileName: uploadFile.name,
        keyName: uploadFile.keyName,
        revision: fileUpdate.revision,
        size: uploadFile.size,
        description: fileUpdate.description,
        tagIds: fileUpdate.tags.map((tag) => tag.id),
        fileHistoryDescription: newDescription,
        version: fileUpdate.version,
        // TODO: ファイル登録 / 編集ダイアログに、フォルダを設定できるコンボボックスを配置する
        // https://break-tmc.atlassian.net/browse/CREW-15053
        folderId: undefined,
      }

      const result = await updateFileMutation({
        needNotification,
        file: payload,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<FileModel> = {
        eventType: NotifyEventType.Updated,
        id: result.file?.id as string,
        object: result.file ?? undefined,
      }

      dispatch(notifyFileEvent(objectEventMessage))

      return result
    },
    [dispatch, updateFileMutation]
  )

  return {
    updateFile,
  }
}
